<template>
  <CCard>
    <CCardHeader>
      <strong>{{ title }}</strong>
    </CCardHeader>
    <CCardBody>
      <AbstractForm :formContent="formContent" @emit-methods="onEmitMethods" :fields="challengeFields" />
    </CCardBody>
  </CCard>
</template>

<script>
import { createNewChallenge } from '@/services/challenges';
import AbstractForm from '@/components/Forms/AbstractForm.vue';

export default {
  name: "addChallenge",
  components: { AbstractForm },
  data() {
    return {
      title: "Nuevo Reto",
      id: null,
      challengeFields: {
        title: null,
        description: null,
        type: null,
        image: null
      },
      formContent: {
        buttons: [
                {
                    textButton: 'Volver',
                    color: 'secondary',
                    event: 'back',
                    style: 'margin: 10px',
                    active: true
                },
                {
                    textButton: 'Crear',
                    color: 'success',
                    event: 'add',
                    style: 'margin: 10px',
                    active: this.$store.state.isAdmin
                },
          ],
          rows: [
            {
              style: '',
              class: '',
              content: [
                {
                  type: 'element',
                  rule: [],
                  style: '',
                  class: '',
                  content: {
                   type: 'input',
                   value: '',
                   key: 'title',
                   label: 'Título del reto',
                   maxLength: 30,
                  },
                },
              ]
            },
            {
              style: '',
              class: '',
              content: [
                {
                  type: 'element',
                  rule: [],
                  style: '',
                  class: '',
                  content: {
                   type: 'textArea',
                   value: '',
                   key: 'description',
                   label: 'Descripción',
                  maxLength: 1000,
                  },
                },
              ]
            },
            {
              style: '',
              class: '',
              content: [
                {
                  type: 'element',
                  rule: [],
                  style: '',
                  class: '',
                  content: {
                   type: 'select',
                   value: '',
                   key: 'type',
                   label: 'Tipo de reto',
                   options: [
                    {
                      value: "angle_min",
                      name: "Ángulo mínimo",
                    },
                    {
                      value: "angle_max",
                      name: "Ángulo máximo",
                    }
                   ],
                  },
                },
              ]
            },
            {
              style: '',
              class: '',
              content: [
                {
                  type: 'element',
                  rule: [],
                  style: '',
                  class: '',
                  content: {
                   type: 'file',
                   value: '',
                   key: 'challenge-image',
                   label: 'Imagen del reto'
                  },
                },
              ]
            }
          ]
      }
    };
  },
  methods: {
    onEmitMethods(event, dataChallenge) {
        switch (event) {
            case 'add': this.addChallenge(dataChallenge); break;
            case 'back': this.back(); break;
            default: break;
        }
    },
    showAlertError(msg) {
       this.$store.state.errorAlert = { status: true, msg: msg.toString() }
    },
    /**
     * Método para validar los inputs del formulario
    */
    checkInputs(dataChallenge) {
        const allowedExtensions = /(\.jpg|\.png|\.webp|\.svg)$/i;
        const validationRules = [
            { field: 'title', message: 'Introduzca el título del reto (máximo de 30 caracteres).', maxLength: 30 },
            { field: 'description', message: 'Introduzca una descripción (máximo de 1000 caracteres).', maxLength: 1000 },
            { field: 'type', message: 'Seleccione un tipo de reto.', value: '' },
            { field: 'challenge-image', message: 'Por favor, selecciona una imagen.', value: '' },
            { field: 'challenge-image', message: 'Tipo de archivo no permitido. Por favor, elige una imagen en formato JPG, PNG, WebP o SVG.', regex: allowedExtensions },
        ];
        for (const rule of validationRules) {
            const value = dataChallenge[rule.field];
            if (!value || (rule.maxLength && value.length > rule.maxLength) || value.size > rule.maxLength || (rule.regex && !rule.regex.test(value))) {
                this.showAlertError(rule.message);
                return false;
            }
        }
            return true;
    },
     /**
     * Función para obtener los datos que el usuario escribe en el formulario
     */
     getDataReadyToCreate(dataChallenge) {
      const formData = new FormData();
      const image = document.getElementById("challenge-image").files[0];
      formData.append("title", dataChallenge.title);
      formData.append("description", dataChallenge.description);
      formData.append("type", dataChallenge.type);
      formData.append("image", image);
      return formData;
    },
    /**
     * Funcion encargada de crear el reto
     */
     addChallenge(dataChallenge) {
      if (this.checkInputs(dataChallenge) == false) return
      
      const maxSizeInBytes = 10 * 1024 * 1024;
      const files = document.getElementById("challenge-image").files;
      const file = files[0];

      if (file.size > maxSizeInBytes) {
        this.showAlertError('La imagen es demasiado grande. El tamaño máximo permitido es de 10MB.');
      }

      createNewChallenge(this.getDataReadyToCreate(dataChallenge)).then(() => {
          this.$router.push("/challenge");
      })
      
    },
     /**
     * Función para volver atrás
     */
     back() {
        this.$router.go(-1);
     }
  }
};
</script>
<style>
textarea.form-control{
  height: 200px;
}
#challenge-image{
  margin: 10px 0;
  border: 0;
}
.challenge-type{
  font-weight: 500;
}
</style>